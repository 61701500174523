let Distributor = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient
    let ServiceBaseAddress = serviceAddr

    /**
     * 获取指定机构下用户分页列表
     * @param startTime
     * @param endTime
     * @param oranizationId
     * @param isAll
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.DistributorList = function (startTime, endTime, oranizationId, isAll, pageIndex, keyWord, departmentId, roleId, isBusiness, isPharmacist ,success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/OrganizationDistributorPages'
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (oranizationId) {
            url += `/${oranizationId}`;
        }
        if (isAll) {
            url += `/${isAll}`;
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}/0`
        } else {
            url += `/%20/0`
        }
        if (departmentId) {
            url += `/${departmentId}`
        }else{
            url += `/0`
        }
        url += `/${roleId}/${isBusiness}/${isPharmacist}`
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    this.DistributorBusinessList = function (startTime, endTime, oranizationId, pageIndex, keyWord, departmentId, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/OrganizationBusinessPages'
        if (startTime) {
            url += `/${startTime}`
        }
        else {
            url += '/%20'
        }
        if (endTime) {
            url += `/${endTime}`
        }
        else {
            url += '/%20'
        }
        if (oranizationId) {
            url += `/${oranizationId}`
        }
        if (pageIndex) {
            url += `/${pageIndex}`
        }
        if (keyWord) {
            url += `/${keyWord}`
        } else {
            url += `/%20`
        }
        if (departmentId) {
            url += `/${departmentId}/true`
        } else {
            url += `/0/true`
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 获取业务员绑定用户列表
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.GetBusinessBandUser = function (organizationId, userId, success, error) {
        var url = ServiceBaseAddress + `/api/Distributor/GetBusinessBandUser/${organizationId}/${userId}`
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    this.SetBusinessBandUser = function (userId,item, success, error) {
        var url = ServiceBaseAddress + `/api/Distributor/SetBusinessBandUser?userId=${userId}`
        TokenClient.Post(
            url,
            true,
            { "Content-Type": "application/json" },
            item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 获取机构所有用户分页列表
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.GetUserAllOrg = function (pageIndex, keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/UserRelation/UserAllOrg'
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 是否获得分红
     * @param userId
     * @param success
     * @param error
     * @constructor
     */
    this.SetIsPublicShare = function (userId, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/SetIsPublicShare/' + userId;
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
         * 获取机构下所有团长
         * @param startTime
         * @param endTime
         * @param oranizationId
         * @param isAll
         * @param pageIndex
         * @param keyWord
         * @param success
         * @param error
         * @constructor
         */
    this.GetAllCommander = function (keyWord, startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/GetAllCommander'
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 后台---获取指定机构下分销员列表
     * @param organizationId
     * @param success
     * @param error
     * @constructor
     */
    this.DistributorAll = function (organizationId, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/OrganizationDistributors'
        if (organizationId) {
            url += `/${organizationId}`;
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    /**
     * 更换上级分销员
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.ChangeParentDis = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/ChangeParentDistributor'
        TokenClient.Post(
            url,
            true,
            { 'Content-Type': 'application/json' },
            item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    /**
     * 获取指定用户下级用户
     * @param startTime
     * @param endTime
     * @param userId
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.UserChildrens = function (startTime, endTime, userId, pageIndex, keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/UserChildrens'
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (userId) {
            url += `/${userId}`;
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 获取团长下所有下级用户
     * @param startTime
     * @param endTime
     * @param userId
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.GetCommanderChildren = function (userId, keyWord, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/GetCommanderChildren'
        if (userId) {
            url += `/${userId}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 获取用户下所有下级用户
     * @param startTime
     * @param endTime
     * @param userId
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.GetStaffChildren = function (userId, keyWord, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/GetStaffChildren'
        if (userId) {
            url += `/${userId}`
        }
        if (keyWord) {
            url += `/${keyWord}`
        }
        else {
            url += '/%20'
        }
        if (pageIndex) {
            url += `/${pageIndex}`
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 机构---分销获利明细
     * @param organizationId
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.OrgProfit = function (organizationId, startTime, endTime, pageIndex, keyWord, pageSize, success, error) {
        var url = ServiceBaseAddress + '/api/Statistics/Distributor/Org'
        if (organizationId) {
            url += `/${organizationId}`;
        }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (pageSize) {
            url += `/${pageSize}`;
        }
        TokenClient.Get(url, true, null, null, function (data) {
            if (success) {
                var jsonObj = JSON.parse(data)
                success(jsonObj)
            }
        },
            error
        )
    }
    /**
     * 机构---会员分销获利详情
     * @param userId
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.OrgUserDetail = function (userId, startTime, endTime, pageIndex, keyWord, pageSize, success, error) {
        var url = ServiceBaseAddress + '/api/Statistics/Distributor/OrgUserDetail'
        if (userId) {
            url += `/${userId}`;
        }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (pageSize) {
            url += `/${pageSize}`;
        }
        TokenClient.Get(url, true, null, null, function (data) {
            if (success) {
                var jsonObj = JSON.parse(data)
                success(jsonObj)
            }
        },
            error
        )
    }
    /**
         * 机构---会员分销获利统计
         * @param organizationId
         * @param startTime
         * @param endTime
         * @param pageIndex
         * @param keyWord
         * @param success
         * @param error
         * @constructor
         */
    this.OrgUserProfit = function (startTime, endTime, pageIndex, keyWord, pageSize, success, error) {
        var url = ServiceBaseAddress + '/api/Statistics/Distributor/OrgUser'
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (pageSize) {
            url += `/${pageSize}`;
        }
        TokenClient.Get(url, true, null, null, function (data) {
            if (success) {
                var jsonObj = JSON.parse(data)
                success(jsonObj)
            }
        },
            error
        )
    }
    /**
         * 会员分享统计
         * @param startTime
         * @param endTime
         * @param pageIndex
         * @param keyWord
         * @param success
         * @param error
         * @constructor
         */
    this.ShareOrg = function (startTime, endTime, pageIndex, keyWord, pageSize, success, error) {
        var url = ServiceBaseAddress + '/api/Statistics/Share/Org'

        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (pageSize) {
            url += `/${pageSize}`;
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
         * 会员分享统计详情
         * @param startTime
         * @param endTime
         * @param pageIndex
         * @param keyWord
         * @param success
         * @param error
         * @constructor
         */
    this.ShareOrgDetail = function (userId, startTime, endTime, pageIndex, keyWord, pageSize, success, error) {
        var url = ServiceBaseAddress + '/api/Statistics/Share/OrgDetail'
        if (userId) {
            url += `/${userId}`;
        }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (pageSize) {
            url += `/${pageSize}`;
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 充值提现记录
     * @param organizationId
     * @param date
     * @param isAll
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.WithdrawalList = function (date, isAll, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/WithdrawalOrRechargeDetails'
        if (date) {
            url += `/${date}`;
        }
        else {
            url += '/%20';
        }
        if (isAll) {
            url += `/${isAll}`;
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null, function (data) {
            if (success) {
                var jsonObj = JSON.parse(data)
                success(jsonObj)
            }
        },
            error
        )
    }

    /**
     * 充值
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.Recharge = function (item, success, error) {
        // var url = ServiceBaseAddress + '/api/Distributor/SubmitRechargeNative'
        var url = ServiceBaseAddress + '/api/WeChatPay/SubmitNative'
        TokenClient.Post(
            url,
            true,
            { 'Content-Type': 'application/json' },
            item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    /**
     * 获取账户余额
     * @param organizationId
     * @param success
     * @param error
     * @constructor
     */
    this.BalanceDetail = function (organizationId, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/UserAssetsDetails'
        if (organizationId) {
            url += `/${organizationId}`;
        }
        TokenClient.Get(url, true, null, null, function (data) {
            if (success) {
                var jsonObj = JSON.parse(data)
                success(jsonObj)
            }
        },
            error
        )
    }

    /**
     * 提现计算
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.CalcWithdrawal = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/WithdrawalCompute'
        TokenClient.Post(
            url,
            true,
            { 'Content-Type': 'application/json' },
            item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    this.ToSystem = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/WithdrawalToSystem'
        TokenClient.Post(
            url,
            true,
            { 'Content-Type': 'application/json' },
            item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 获取账单列表
     * @param organizationId
     * @param startTime
     * @param endTime
     * @param keyWord
     * @param type
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.BillList = function (organizationId, startTime, endTime, keyWord, type, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/GetOrgAssetsDetails'
        if (organizationId) {
            url += `/${organizationId}`;
        }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (type) {
            url += `/${type}`;
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url, true, null, null, function (data) {
            if (success) {
                var jsonObj = JSON.parse(data)
                success(jsonObj)
            }
        },
            error
        )
    }
    /**
     * 获取所有私域会员
     * @param startTime
     * @param endTime
     * @param userId
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.GetOrgPrivateTraffic = function (keyWord, startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/GetOrgPrivateTraffic'
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }

        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 获取团长分红记录
     * @param {*} keyWord 
     * @param {*} startTime 
     * @param {*} endTime 
     * @param {*} pageIndex 
     * @param {*} success 
     * @param {*} error 
     */
    this.AllTeamShareRecore = function (userId, keyWord, startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/AllTeamShareRecore'
        if (userId || userId==0) {
            url += `/${userId}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        } else {
            url += '/%20';
        }
        if (startTime) {
            url += `/${startTime}`;
        } else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        } else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }

        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 获取交易用户分红记录
     * @param {*} keyWord 
     * @param {*} startTime 
     * @param {*} endTime 
     * @param {*} pageIndex 
     * @param {*} success 
     * @param {*} error 
     */
     this.GetCustomerPublicShareAllOrg = function (userId, keyWord, startTime, endTime, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/GetCustomerPublicShareAllOrg'
        if (userId) {
            url += `/${userId}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        } else {
            url += '/%20';
        }
        if (startTime) {
            url += `/${startTime}`;
        } else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        } else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }

        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
    * 总后台--团长分红纪录
    * @param keyWord
    * @param pageIndex
    * @param success
    * @param error
    * @constructor
    */
    this.TeamShareRecoreOrg = function (userId, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/TeamShareRecoreOrg/' + userId;

        if (pageIndex) {
            url += `/${pageIndex}`;
        }

        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取统一分红记录
     * @param startTime
     * @param endTime
     * @param userId
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.GetPublicShareRecord = function (keyWord, startTime, endTime, pageIndex, pageSize, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/GetPublicShareRecord'
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (pageSize) {
            url += `/${pageSize}`;
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 获取历史用户分红记录
     * @param startTime
     * @param endTime
     * @param userId
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.GetCustomerRecord = function (keyWord, startTime, endTime, pageIndex, pageSize, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/GetCustomerRecord'
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (pageSize) {
            url += `/${pageSize}`;
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 获取分红详情
     * @param type 0统一分红1团队分红2历史交易用户
     * @param pageIndex
     * @param recordId
     * @param success
     * @param error
     * @constructor
     */
    this.PublicShareUserDetail = function (recordId, type, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/PublicShareUserDetail/' + recordId + '/' + type + '/' + pageIndex
        // if (pageSize) {
        //     url += `/${pageSize}`;
        // }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
    * 查询分红人员详情
    * @param {} success 
    * @param {*} error 
    */
    this.GetPublicShareInfo = function (success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/GetPublisSharInfo'
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
    * 查询统一分红详情
    * @param {} success 
    * @param {*} error 
    */
    this.GetCustomerPublisSharInfo = function (success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/GetCustomerPublisSharInfo'
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 设置分红
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.SubmitInvoice = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/Invoice'
        TokenClient.Post(
            url,
            true,
            { 'Content-Type': 'application/json' },
            item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 获取公益资金明细
     * @param publicShareModel 公益分红模式 0统一分红模式 1团队模式 2交易用户模式
     * @param startTime
     * @param endTime
     * @param userId
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.GetPublicShareDetail = function (publicShareModel,keyWord, startTime, endTime, pageIndex, pageSize, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/GetPublicShareDetail/'+publicShareModel
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (pageSize) {
            url += `/${pageSize}`;
        }

        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 获取公益资金明细
     * @param startTime
     * @param endTime
     * @param userId
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.OrgMyTeamPublickShareDetail = function (userId, keyWord, pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/OrgMyTeamPublicShareDetail/' + userId
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }

        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 设置分红
     * @param item
     * @param success
     * @param error
     * @constructor
     */
    this.SubmitShare = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/UpdatePublicSharePercent'
        TokenClient.Post(
            url,
            true,
            { 'Content-Type': 'application/json' },
            item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 统一分红确认分红
     * @param success
     * @param error
     * @constructor
     */
    this.DistributePublicShare = function (success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/DistributePublicShare'
        TokenClient.Post(
            url,
            true,
            { 'Content-Type': 'application/json' },
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 历史交易确认分红
     * @param success
     * @param error
     * @constructor
     */
     this.CustomerPublicShare = function (success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/CustomerPublicShare'
        TokenClient.Post(
            url,
            true,
            { 'Content-Type': 'application/json' },
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    this.ConsumptionTotal = function (param, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/ConsumptionTotal';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 设为团长
     * @param {*} param 
     * @param {*} success 
     * @param {*} error 
     */
    this.AddCommander = function (userId, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/SetCommander/' + userId;
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 设置员工是否有审核处方的权限
     * @param {*} param 
     * @param {*} success 
     * @param {*} error 
     */
    this.setAuditDiagnose = function (userId, success, error) {
        var url = ServiceBaseAddress + '/api/User/AuditDiagnose/' + userId;
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 设置员工是否有自主开放的权限
     * @param {*} param 
     * @param {*} success 
     * @param {*} error 
     */
    this.setMakeDiagnose = function (userId, success, error) {
        var url = ServiceBaseAddress + '/api/User/MakeDiagnose/' + userId
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 机构代团长分红
     * @param {*} param 
     * @param {*} success 
     * @param {*} error 
     */
    this.TeamAccountOrg = function (param, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/TeamAccountOrg';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取团队信息
     * @param {*} param 
     * @param {*} success 
     * @param {*} error 
     */
    this.TeamChildrenLeve = function (userId, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/OrgTeamChildrenLeve/' + userId;
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 机构后台--查询该机构下所有员工用Id
     * @param {*} param 
     * @param {*} success 
     * @param {*} error 
     */
    this.OrgStaffIds = function (success, error) {
        var url = ServiceBaseAddress + '/api/Organization/OrgStaffIds';
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 获取指定机构下用户分页列表
     * @param startTime
     * @param endTime
     * @param oranizationId
     * @param isAll
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.ZZDistributorList = function (keyWord, startMoney, endMoney,pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/User/GetFairAllUser'
        if (keyWord) {
            url += `/${keyWord}/${startMoney}/${endMoney}`
        }
        else {
            url += `/%20/${startMoney}/${endMoney}`
        }
        if (pageIndex) {
            url += `/${pageIndex}`
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

}

export { Distributor }
